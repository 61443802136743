.home-page {
  text-align: center;
  padding: 20px;
}

.home-page-title {
  font-size: 30px;
  margin-bottom: 30px;
}

.article-tiles {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.article-tile {
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.article-tile:hover {
  transform: translateY(-5px);
}

.article-image-container {
  overflow: hidden;
  border-radius: 8px 8px 0 0;
}

.article-image {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.article-caption {
  font-size: 30;
  padding-top: 10px;
  padding-bottom: 10px;
}
